

























import { Component, Vue, Watch } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";

@Component({
  components: {}
})
export default class Downloads extends Vue {
  downloadsItems = [
    {
      text: this.$t("download.items.firstItem.header") as TranslateResult,
      photo: "photo-1",
      href: "/media/jakosciowe-oznaczenie-wlosy.doc"
    },
    {
      text: this.$t("download.items.secondItem.header") as TranslateResult,
      photo: "photo-2",
      href: "/media/anaboliki-w-moczu.doc"
    },
    {
      text: this.$t("download.items.thirdItem.header") as TranslateResult,
      photo: "photo-3",
      href: "/media/lista-substancji.doc"
    }
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.downloadsItems = [
      {
        text: this.$t("download.items.firstItem.header") as TranslateResult,
        photo: "photo-1",
        href: "/media/jakosciowe-oznaczenie-wlosy.doc"
      },
      {
        text: this.$t("download.items.secondItem.header") as TranslateResult,
        photo: "photo-2",
        href: "/media/anaboliki-w-moczu.doc"
      },
      {
        text: this.$t("download.items.thirdItem.header") as TranslateResult,
        photo: "photo-3",
        href: "/media/lista-substancji.doc"
      }
    ];
  }

  download(href) {
    const link = document.createElement("a");
    link.href = href;
    link.download = href.substr(href.lastIndexOf("/") + 1);
    link.click();
  }
}
