<template>
  <div class="price-list-page">
    <OffersComponent is-price-list="true"/>
    <Downloads/>
  </div>
</template>

<script>
import OffersComponent from "@/components/OffersComponent.vue";
import Downloads from "@/components/Downloads.vue";

export default {
  name: "PriceListPage",
  components: {
    OffersComponent,
    Downloads
  }
};
</script>
